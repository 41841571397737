import { BaseService } from './baseService'
import { API_PATH } from '~/utility/constants/api-path'

export class IndexServices extends BaseService {
  async getTopTimeLimits() {
    const [discountRes, settlementRes, campaignRes] = await Promise.all([
      this.api.get(API_PATH.ENTRY_DISCOUNT_TIME_LIMIT),
      this.api.get(API_PATH.ENTRY_SETTLEMENT_TIME_LIMIT),
      this.api.get(API_PATH.ENTRY_CAMPAIGN),
    ])
    // TODO エラーハンドリング。現在は settlementRes.data => { errors: ['Not Found']} が返答されている。
    return {
      data: {
        entryDiscountTimeLimit: discountRes?.data?.entry_discount_time_limit || null,
        entrySettlementTimeLimit: settlementRes?.data?.entry_settlement_time_limit || null,
        entryPaymentUrl: settlementRes?.data?.payment_url || null,
        entryCampaign: campaignRes?.data?.entry_campaign || null,
      },
    }
  }
}
